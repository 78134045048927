import React from "react"
import merlinsLogo from "../images/Merlin.png"
import cysecLogo from "../images/Cysec.png"
import dastaLogo from "../images/DASTA.png"
import colpalLogo from "../images/Colgate.png"
import bgLogo from "../images/BG.png"
// import colpalLogo from "../images/Colpal_LOGO.png"
// import dastaLogo from "../images/DASTA_LOGO.png"
// import cysecLogo from "../images/Cysec_LOGO.png"

function Client() {
  return (
    <div className="flex justify-center flex-col md:p-[50px]" id="client">
      <h1 className="text-5xl p-[50px] not-italic font-normal text-center">
        Our Clients
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-5 p-[20px] md:py-[50px]">
        <div className="flex flex-col justify-center items-center p-8">
          <a href="https://www.merlinssolutions.com/" target="_blank">
            <img className="object-contain h-24" alt="" src={merlinsLogo} />
          </a>
        </div>
        <div className="flex flex-col justify-center items-center p-8">
          <a href="https://bhalogari.com/" target="_blank">
            <img className="object-contain h-24" alt="" src={bgLogo} />
          </a>
        </div>
        <div className="flex flex-col justify-center items-center p-8">
          <img className="object-contain h-24" alt="" src={cysecLogo} />
        </div>
        <div className="flex flex-col justify-center items-center p-8">
          <a href="https://www.dasta.or.th/th" target="_blank">
            <img className="object-contain h-24" alt="" src={dastaLogo} />
          </a>
        </div>
        <div className="flex flex-col justify-center items-center p-8">
          <a href="https://www.colgatepalmolive.co.th/" target="_blank">
            <img className="object-contain h-24" alt="" src={colpalLogo} />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Client
